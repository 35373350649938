import { isEmpty } from "lodash";
import toastr from "src/components/Common/toastr";
import { AppAnalytics } from "src/helpers";
import checkValidMOSData from "src/modules/peerInsights/helpers/checkValidMOS";
import { v4 as uuid4 } from "uuid";
import api from "../../api";
import { API_CALL_STATE } from "../../constants";
import { getAPIURL } from "../../utils";

export const SET_PEER_INSIGHTS_CHART_EVENTS = "SET_PEER_INSIGHTS_CHART_EVENTS";

export const FETCH_ROOM_DEBUGGER_DETAILS_INIT =
  "FETCH_ROOM_DEBUGGER_DETAILS_INIT";
export const FETCH_ROOM_DEBUGGER_DETAILS_DONE =
  "FETCH_ROOM_DEBUGGER_DETAILS_DONE";
export const FETCH_ROOM_DEBUGGER_DETAILS_FAIL =
  "FETCH_ROOM_DEBUGGER_DETAILS_FAIL";
export const FETCH_ROOM_DEBUGGER_DETAILS_RESET =
  "FETCH_ROOM_DEBUGGER_DETAILS_RESET";
export const RESET_ROOM_DEBUGGER_STATUS = "RESET_ROOM_DEBUGGER_STATUS";

export const SET_PEER_INSIGHTS_DETAILS_DERIVED_STATES =
  "SET_PEER_INSIGHTS_DETAILS_DERIVED_STATES";

export const FETCH_ANALYTICS_PEERS_DETAILS_INIT =
  "FETCH_ANALYTICS_PEERS_DETAILS_INIT";
export const FETCH_ANALYTICS_PEERS_DETAILS_DONE =
  "FETCH_ANALYTICS_PEERS_DETAILS_DONE";
export const FETCH_ANALYTICS_PEERS_DETAILS_FAIL =
  "FETCH_ANALYTICS_PEERS_DETAILS_FAIL";

export const FETCH_ANALYTICS_PEERS_WEBRTC_STATS_INIT =
  "FETCH_ANALYTICS_PEERS_WEBRTC_STATS_INIT";
export const FETCH_ANALYTICS_PEERS_WEBRTC_STATS_DONE =
  "FETCH_ANALYTICS_PEERS_WEBRTC_STATS_DONE";
export const FETCH_ANALYTICS_PEERS_WEBRTC_STATS_FAIL =
  "FETCH_ANALYTICS_PEERS_WEBRTC_STATS_FAIL";

export const FETCH_ANALYTICS_PEERS_EVENT_LOGS_INIT =
  "FETCH_ANALYTICS_PEERS_EVENT_LOGS_INIT";
export const FETCH_ANALYTICS_PEERS_EVENT_LOGS_DONE =
  "FETCH_ANALYTICS_PEERS_EVENT_LOGS_DONE";
export const FETCH_ANALYTICS_PEERS_EVENT_LOGS_FAIL =
  "FETCH_ANALYTICS_PEERS_EVENT_LOGS_FAIL";

export const FETCH_USAGE_OVERVIEW_INIT = "FETCH_USAGE_OVERVIEW_INIT";
export const FETCH_USAGE_OVERVIEW_DONE = "FETCH_USAGE_OVERVIEW_DONE";
export const FETCH_USAGE_OVERVIEW_FAIL = "FETCH_USAGE_OVERVIEW_FAIL";

export const FETCH_PEER_PUBLISH_DETAILS_INIT =
  "FETCH_PEER_PUBLISH_DETAILS_INIT";
export const FETCH_PEER_PUBLISH_DETAILS_DONE =
  "FETCH_PEER_PUBLISH_DETAILS_DONE";
export const FETCH_PEER_PUBLISH_DETAILS_FAIL =
  "FETCH_PEER_PUBLISH_DETAILS_FAIL";

export const FETCH_PEER_ISSUES_DETAILS_INIT = "FETCH_PEER_ISSUES_DETAILS_INIT";
export const FETCH_PEER_ISSUES_DETAILS_DONE = "FETCH_PEER_ISSUES_DETAILS_DONE";
export const FETCH_PEER_ISSUES_DETAILS_FAIL = "FETCH_PEER_ISSUES_DETAILS_FAIL";

export const FETCH_PEER_QUALITY_ISSUES_DETAILS_INIT =
  "FETCH_PEER_QUALITY_ISSUES_DETAILS_INIT";
export const FETCH_PEER_QUALITY_ISSUES_DETAILS_DONE =
  "FETCH_PEER_QUALITY_ISSUES_DETAILS_DONE";
export const FETCH_PEER_QUALITY_ISSUES_DETAILS_FAIL =
  "FETCH_PEER_QUALITY_ISSUES_DETAILS_FAIL";

export const FETCH_USAGE_OVERVIEW_RESET = "FETCH_USAGE_OVERVIEW_RESET";

export const PATCH_ROOM_DEBUGGER_DETAILS_INIT =
  "PATCH_ROOM_DEBUGGER_DETAILS_INIT";
export const PATCH_ROOM_DEBUGGER_DETAILS_DONE =
  "PATCH_ROOM_DEBUGGER_DETAILS_DONE";
export const PATCH_ROOM_DEBUGGER_DETAILS_FAIL =
  "PATCH_ROOM_DEBUGGER_DETAILS_FAIL";

export const UPDATE_ROOM_DEBUGGER_DETAILS = "UPDATE_ROOM_DEBUGGER_DETAILS";

export function fetchRoomDebuggerDetails() {
  return async (dispatch, getState) => {
    const { roomDebuggerDetailsStatus, roomDebuggerFilter } =
      getState().roomDebugger;
    if (roomDebuggerDetailsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_ROOM_DEBUGGER_DETAILS_INIT });
    if (roomDebuggerFilter.start_time) {
      roomDebuggerFilter.start_time = "";
    }
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("analytics/realtime/room-v3"), {
          ...roomDebuggerFilter,
          limit: 100,
        });
      dispatch({
        type: FETCH_ROOM_DEBUGGER_DETAILS_DONE,
        payload: {
          event: [...(resp?.data?.event || [])],
          next_page: resp?.data?.next_page,
          isLive: resp?.data?.isLive,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_ROOM_DEBUGGER_DETAILS_FAIL });
      toastr.error(e.response.data.msg);
    }

    try {
      AppAnalytics.track("eventInspector.repsonse", {
        responseType: roomDebuggerFilter.error_events ? "error" : "basic",
        platform: "hms-dashboard",
        searchId: uuid4(),
        ...roomDebuggerFilter,
      });
    } catch (e) {
      console.error(e);
    }
  };
}

export function fetchPeersAnalyticsDetails(peerId) {
  return async (dispatch, getState) => {
    const { fetchPeerAnalyticsDetailsStatus } = getState().roomDebugger;
    if (fetchPeerAnalyticsDetailsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_ANALYTICS_PEERS_DETAILS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL("analytics/peers/details"), {
          peer_id: peerId,
        });
      if (resp?.data?.success) {
        dispatch({
          type: FETCH_ANALYTICS_PEERS_DETAILS_DONE,
          payload: resp.data.data,
        });
        dispatch(
          setPeerInsightsDerivedStates({
            publish:
              !resp.data.data.video_published &&
              !resp.data.data.screen_published &&
              !resp.data.data.audio_published
                ? false
                : true,
          })
        );

        AppAnalytics.track("peerDetails.response.success", {
          peerId: peerId,
          peerDetails: resp.data.data,
        });
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_ANALYTICS_PEERS_DETAILS_FAIL });
      toastr.error(e?.response?.data?.msg || e?.message);
      AppAnalytics.track("peerDetails.response.failed", {
        peerId: peerId,
      });
    }
  };
}

export function fetchPeersAnalyticsEventLogs(peerId) {
  return async (dispatch, getState) => {
    const { fetchPeerAnalyticsEventLogsStatus } = getState().roomDebugger;
    if (fetchPeerAnalyticsEventLogsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_ANALYTICS_PEERS_EVENT_LOGS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL("analytics/peers/details/event-logs"), {
          peer_id: peerId,
        });
      if (resp?.data?.success) {
        dispatch({
          type: FETCH_ANALYTICS_PEERS_EVENT_LOGS_DONE,
          payload: resp.data.data,
        });
        AppAnalytics.track("eventlogs.response.success", {
          peerId: peerId,
          eventLogs: resp.data.data,
        });
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_ANALYTICS_PEERS_EVENT_LOGS_FAIL });
      toastr.error(e?.response?.data?.msg || e?.message);
      AppAnalytics.track("eventlogs.response.failed", {
        peerId: peerId,
      });
    }
  };
}

export function fetchPeersAnalyticsPeerWebrtcStats(peerId) {
  return async (dispatch, getState) => {
    const { fetchPeerAnalyticsPeerWebrtcStatsStatus } = getState().roomDebugger;
    if (
      fetchPeerAnalyticsPeerWebrtcStatsStatus === API_CALL_STATE.IN_PROGRESS
    ) {
      return;
    }
    dispatch({ type: FETCH_ANALYTICS_PEERS_WEBRTC_STATS_INIT });
    try {
      let resp = await api
        .service("dashboard")
        .get(getAPIURL("analytics/peers/details/peer-webrtc-stats-v2"), {
          peer_id: peerId,
        });
      let issues = {};

      if (!isEmpty(resp?.data?.data)) {
        const validMos =
          resp?.data?.data?.mos_score_and_quality_issue_summary?.mos_score
            ?.length > 0 &&
          checkValidMOSData(
            resp?.data?.data?.mos_score_and_quality_issue_summary?.mos_score
          );
        if (validMos) {
          issues = {
            publisher_bitrate:
              resp?.data?.data?.mos_score_and_quality_issue_summary
                ?.publisher_bitrate_windows,
            subscriber_network:
              resp?.data?.data?.mos_score_and_quality_issue_summary
                ?.subscriber_network_windows,
          };
        }

        dispatch(
          setPeerInsightsDerivedStates({
            subscribe: validMos,
          })
        );
      }

      if (isEmpty(issues)) {
        dispatch({
          type: FETCH_ANALYTICS_PEERS_WEBRTC_STATS_DONE,
          payload: {
            peerMosScoreAndQualityIssues: {
              ...resp?.data?.data?.mos_score_and_quality_issue_summary,
            },
            subscriberStats: { ...resp?.data?.data?.subscriber_stats },
            iceCandidates: { ...resp?.data?.data?.ice_candidates },
          },
        });
      } else {
        dispatch({
          type: FETCH_ANALYTICS_PEERS_WEBRTC_STATS_DONE,
          payload: {
            peerMosScoreAndQualityIssues: {
              ...resp?.data?.data?.mos_score_and_quality_issue_summary,
              issues: { ...issues },
            },
            subscriberStats: { ...resp?.data?.data?.subscriber_stats },
            iceCandidates: { ...resp?.data?.data?.ice_candidates },
          },
        });
      }

      AppAnalytics.track("peersWebrtcStats.response.success", {
        peerId: peerId,
        peerWebrtcStats: resp.data.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_ANALYTICS_PEERS_WEBRTC_STATS_FAIL });
      toastr.error(e?.response?.data?.msg || e?.message);
      AppAnalytics.track("peersWebrtcStats.response.failed", {
        peerId: peerId,
      });
    }
  };
}
export function patchRoomDebuggerDetails({ isHistorical = false }) {
  return async (dispatch, getState) => {
    const {
      roomDebuggerDetails,
      roomDebuggerDetailsStatus,
      roomDebuggerFilter,
    } = getState().roomDebugger;
    const [latestEvent] = roomDebuggerDetails?.newEvent?.length
      ? roomDebuggerDetails.newEvent
      : roomDebuggerDetails.event;
    if (!isHistorical) {
      roomDebuggerFilter.start_time = latestEvent?.timestamp || "";
    } else {
      const lastEvent = roomDebuggerDetails?.event?.length
        ? roomDebuggerDetails.event[roomDebuggerDetails?.event?.length - 1]
        : "";
      roomDebuggerFilter.before_timestamp = lastEvent.timestamp;
    }
    delete roomDebuggerFilter.limit;

    if (roomDebuggerDetailsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: PATCH_ROOM_DEBUGGER_DETAILS_INIT });

    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("analytics/realtime/room-v3"), {
          ...roomDebuggerFilter,
        });
      dispatch({
        type: PATCH_ROOM_DEBUGGER_DETAILS_DONE,
        payload: {
          newEvent: resp?.data?.event || [],
          next_page: resp?.data?.next_page || false,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: PATCH_ROOM_DEBUGGER_DETAILS_FAIL });
      toastr.error(e.response.data.msg);
    }
  };
}

export const MERGE_NEW_ROOM_DEBUGGER_EVENTS = "MERGE_NEW_ROOM_DEBUGGER_EVENTS";

export const SET_LIVE_ROOM_DEBUGGER_FLAG = "SET_LIVE_ROOM_DEBUGGER_FLAG";
export const DISABLE_LIVE_ROOM_DEBUGGER = "DISABLE_LIVE_ROOM_DEBUGGER";
export const ENABLE_LIVE_ROOM_DEBUGGER = "ENABLE_LIVE_ROOM_DEBUGGER";

let timerId = null;
export const toggleLiveEvents = () => {
  return dispatch => {
    if (timerId) {
      clearInterval(timerId);
      timerId = null;
    } else {
      timerId = setInterval(
        () => dispatch(patchRoomDebuggerDetails({ isHistorical: false })),
        5000
      );
    }
    dispatch({
      type: timerId ? ENABLE_LIVE_ROOM_DEBUGGER : DISABLE_LIVE_ROOM_DEBUGGER,
    });
  };
};

export const UPDATE_ROOM_DEBUGGER_FILTER = "UPDATE_ROOM_DEBUGGER_FILTER";
export const updateRoomDebuggerFilter = ({ key, value }) => {
  return dispatch => {
    if (!key) {
      return;
    }
    dispatch({
      type: UPDATE_ROOM_DEBUGGER_FILTER,
      payload: {
        key,
        value,
      },
    });
  };
};

export const CLEAR_ROOM_DEBUGGER_FILTER = "CLEAR_ROOM_DEBUGGER_FILTER";
export const clearRoomDebuggerFilter = () => {
  return dispatch => {
    if (timerId) {
      dispatch(toggleLiveEvents());
    }
    dispatch({ type: CLEAR_ROOM_DEBUGGER_FILTER });
  };
};

export const CLEAR_ROOM_DEBUGGER_DETAILS = "CLEAR_ROOM_DEBUGGER_DETAILS";
export const clearRoomDebuggerDetails = () => {
  return dispatch => {
    if (timerId) {
      dispatch(toggleLiveEvents());
    }
    dispatch({ type: CLEAR_ROOM_DEBUGGER_DETAILS });
  };
};

export const MERGE_NEW_EVENTS_FETCHED = "MERGE_NEW_EVENTS_FETCHED";
export const mergeNewEventsFetched = () => {
  return dispatch => {
    dispatch({
      type: MERGE_NEW_EVENTS_FETCHED,
    });
  };
};

export const mergeAndFetchHistoricalEvents = () => {
  return async dispatch => {
    await dispatch(
      patchRoomDebuggerDetails({
        isHistorical: true,
      })
    );
    await dispatch(mergeNewEventsFetched());
  };
};

export function fetchUsageOverview(month, year) {
  return async (dispatch, getState) => {
    const { fetchUsageOverviewStatus } = getState().roomDebugger;
    if (fetchUsageOverviewStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_USAGE_OVERVIEW_INIT });
    try {
      const resp = await api.service("dashboard").get(
        getAPIURL("analytics/usage-overview/organisation", {
          month: month,
          year: year,
        })
      );
      if (resp.data.success) {
        dispatch({
          type: FETCH_USAGE_OVERVIEW_DONE,
          payload: resp.data.data,
        });
      } else {
        throw Error(resp.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_USAGE_OVERVIEW_FAIL });
      toastr.error(e.message);
    }
  };
}

export function resetFetchUsageOverview() {
  return dispatch => {
    dispatch({
      type: FETCH_USAGE_OVERVIEW_RESET,
    });
  };
}

export function fetchPeersAnalyticsPublishScore(peerId) {
  return async (dispatch, getState) => {
    const { fetchPeerAnalyticsPublishScoreStatus } = getState().roomDebugger;
    if (fetchPeerAnalyticsPublishScoreStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_PEER_PUBLISH_DETAILS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL("analytics/peers/details/peer-publisher-stats"), {
          peer_id: peerId,
        });

      if (resp?.data?.success) {
        dispatch({
          type: FETCH_PEER_PUBLISH_DETAILS_DONE,
          payload: resp.data.data,
        });
        AppAnalytics.track("peerPublishScore.response.success", {
          score: resp.data.data,
          peerId: peerId,
        });
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_PEER_PUBLISH_DETAILS_FAIL });
      toastr.error(e?.response?.data?.msg || e?.message);
      AppAnalytics.track("peerPublishScore.response.failed", {
        peerId: peerId,
      });
    }
  };
}

export function fetchPeersAnalyticsIssueDetails(peerId) {
  return async (dispatch, getState) => {
    const { fetchPeerAnalyticsIssueDetailsStatus } = getState().roomDebugger;
    if (fetchPeerAnalyticsIssueDetailsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_PEER_ISSUES_DETAILS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL("analytics/peers/details/peer-issue-details"), {
          peer_id: peerId,
        });

      if (resp?.data?.success) {
        dispatch({
          type: FETCH_PEER_ISSUES_DETAILS_DONE,
          payload: resp.data.data,
        });
        AppAnalytics.track("peerIssueDetails.response.success", {
          score: resp.data.data,
          peerId: peerId,
        });
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_PEER_ISSUES_DETAILS_FAIL });
      toastr.error(e?.response?.data?.msg || e?.message);
      AppAnalytics.track("peerIssueDetails.response.failed", {
        peerId: peerId,
      });
    }
  };
}

export function setPeerInsightsChartEvents(params) {
  return dispatch => {
    dispatch({
      type: SET_PEER_INSIGHTS_CHART_EVENTS,
      // to ensure that we can use this same action to update multiple charts
      payload: { [params.name]: { [params.type]: params } },
    });
  };
}

export function fetchPeersAnalyticsQualityIssueDetails(peerId) {
  return async (dispatch, getState) => {
    const { fetchPeerAnalyticsQualityIssueDetailsStatus } =
      getState().roomDebugger;
    if (
      fetchPeerAnalyticsQualityIssueDetailsStatus === API_CALL_STATE.IN_PROGRESS
    ) {
      return;
    }
    dispatch({ type: FETCH_PEER_QUALITY_ISSUES_DETAILS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL("analytics/peers/details/peer-quality-issue-details"), {
          peer_id: peerId,
        });

      if (resp?.data?.success) {
        const issues = {
          publisher_cpu: resp?.data?.data?.publisher_cpu_limitation,
        };
        dispatch({
          type: FETCH_PEER_QUALITY_ISSUES_DETAILS_DONE,
          payload: issues,
        });
        AppAnalytics.track("peerQualityIssueDetails.response.success", {
          score: resp.data.data,
          peerId: peerId,
        });
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_PEER_QUALITY_ISSUES_DETAILS_FAIL });
      toastr.error(e?.response?.data?.msg || e?.message);
      AppAnalytics.track("peerQualityIssueDetails.response.failed", {
        peerId: peerId,
      });
    }
  };
}

export const setPeerInsightsDerivedStates = params => {
  return async (dispatch, getState) => {
    const { peerInsightsDerivedStates } = getState().roomDebugger;
    dispatch({
      type: SET_PEER_INSIGHTS_DETAILS_DERIVED_STATES,
      payload: { ...peerInsightsDerivedStates, ...params },
    });
  };
};
